import { fetchResponseWithBody,fetchResponse } from "../../restapiutil/RestAPIClient";


const APPLICATION_JSON = "application/json";

export const registerationObj = async (databody) =>{
    const dataurl = "reserve/register";
    const headers = { Accept : APPLICATION_JSON, "Content-Type": APPLICATION_JSON };
    return await fetchResponseWithBody(dataurl,"POST",headers,{},databody);
};

  
export const  deleteOrder= async(obj) =>{
    const dataurl = "reserve/delete";
    const APPLICATION_JSON = "application/json";
    const headers = { Accept : APPLICATION_JSON, "Content-Type": APPLICATION_JSON};
     await fetchResponseWithBody(dataurl,"DELETE",headers,{},{id:obj.id,shopRefId: obj.shopRefId});
   
}


