import React from 'react';

class Privacy extends React.Component {
   

  render() {
    return (
      <div class="continer-fluid p-3">
      <div class='row'>
   <div class="col-md-12"> 
   <h2>Datenschutzerklärung</h2>
   <h5>Datenschutzerklärung </h5>
   <p>Vielen Dank für Ihr Interesse an unserem Onlineauftritt. Der Schutz Ihrer persönlichen Daten liegt uns sehr am Herzen. An dieser Stelle möchten wir Sie daher über den Datenschutz in unserem Unternehmen informieren. Selbstverständlich beachten wir die gesetzlichen Bestimmungen des Datenschutzgesetzes (DSG2000, EUDSGVO) des Telekommunikations-gesetzes (TKG) und anderer datenschutzrechtlicher Bestimmungen. </p>
<p>Bei Ihren persönlichen Daten können Sie uns vertrauen! Sie werden durch digitale Sicherheitssysteme verschlüsselt und an uns übertragen. Unsere Webseiten sind durch technische Maßnahmen gegen Beschädigungen, Zerstörung oder unberechtigten Zugriff geschützt. Gegenstand des Datenschutzes Gegenstand des Datenschutzes sind personenbezogene Daten. Diese sind nach EUDSGVO Einzelangaben über persönliche oder sachliche Verhältnisse einer bestimmten oder bestimmbaren natürlichen Person. Hierunter fallen z. B. Angaben wie Name, Post-Adresse, E-Mail-Adresse oder Telefonnummer, ggf. aber auch Nutzungsdaten wie Ihre IP-Adresse. </p>
<h5>Umfang der Datenerhebung und -speicherung </h5>
<p>Im Allgemeinen ist es für die Nutzung unserer Internetseite nicht erforderlich, dass Sie personenbezogene Daten angeben. Damit wir unsere Dienstleistungen aber tatsächlich erbringen können, benötigen wir ggf. Ihre personenbezogenen Daten. Dies gilt vor allem für die Beantwortung individueller Anfragen. Wenn Sie uns mit der Erbringung einer Dienstleistung beauftragen, erheben und speichern wir Ihre persönlichen Daten grundsätzlich nur, soweit es für die Erbringung der Dienstleistung oder die Durchführung des Auftrages notwendig ist,  z.B. Vorname, Nachname, Postadresse, Telefonnummer, Email Adresse. </p>
<p>Dazu kann es erforderlich sein, Ihre persönlichen Daten an Unternehmen weiterzugeben, die wir zur Erbringung der Dienstleistung oder zur Vertragsabwicklung einsetzen. BESCHREIBUNG DER EMPFÄNGER: z.B. Versandunternehmen, Lieferdienste, Transportunternehmen, Service Dienste </p>
<p>Eine Weitergabe Ihrer persönlichen Daten an Dritte erfolgt ohne Ihre ausdrückliche Einwilligung nicht, sofern dies nicht zur Erbringung der Dienstleistung oder zur Vertragsdurchführung notwendig ist (wie oben angegeben). Auch die Übermittlung an auskunftsberechtigte staatliche Institution und Behörden erfolgt nur im Rahmen der gesetzlichen Auskunftspflichten oder wenn wir durch eine gerichtliche Entscheidung zur Auskunft verpflichtet werden. In letzterem Fall werden die Betroffenen über die Übermittlung der Daten umgehend informiert. Nach vollständiger Vertragsabwicklung werden Ihre Daten nach Ablauf des Verwendungszweckes und der steuer- und handelsrechtlichen Aufbewahrungsvorschriften gelöscht, sofern Sie nicht Seite 6 von 19 ausdrücklich einer darüber hinausgehende Datenverwendung zugestimmt haben (siehe Löschunganrecht).</p>
<h5>Erhebung und Speicherung von Nutzungsdaten </h5>
<p>Zur Optimierung unserer Webseite sammeln und speichern wir Daten wie z. B. Datum und Uhrzeit des Seitenaufrufs oder die Seite, von der Sie unsere Seite aufgerufen haben. Dies erfolgt anonymisiert, ohne den Benutzer der Seite persönlich zu identifizieren. Unter Umständen werden Nutzerprofile mittels eines Pseudonyms erstellt. Auch hierbei erfolgt keine Verbindung zwischen der hinter dem Pseudonym stehenden natürlichen Personen mit den erhobenen Nutzungsdaten. Diese Daten erheben wir ausschließlich zu statistischen Zwecken, um unseren Internetauftritt weiter zu optimieren und unsere Internetangebote noch attraktiver gestalten zu können. Die Erhebung und Speicherung erfolgt ausschließlich in anonymisierter oder pseudonymisierter Form und lässt keinen Rückschluss auf Sie als natürlich Person zu. </p>
<h5>Zweckgebundene Datenverwendung </h5>
<p>Wir beachten den Grundsatz der zweckgebundenen Daten-Verwendung und erheben, verarbeiten und speichern personenbezogene Daten nur für die Zwecke, die legitim sind und eine entsprechende Rechtsgrundlage besitzen zB. Bearbeitung von Reservierungsanfragen. Auch den unternehmensinternen Datenschutz nehmen wir sehr ernst. Unsere Mitarbeiter und die von uns beauftragten Dienstleistungsunternehmen sind von uns zur Verschwiegenheit und zur Einhaltung der datenschutzrechtlichen Bestimmungen verpflichtet worden. </p>
<h5>Auskunfts-, Löschungs- und Widerspruchsrecht sowie Kontaktdaten </h5>
<p>Ihnen steht das Recht zu, jederzeit in angemessenem Umfang Auskunft über bzw. Löschung der von Ihnen verarbeiteten personenbezogenen Daten zu verlangen. Ferner steht Ihnen das Recht zu, diese Daten berichtigen oder sperren zu lassen. Ebenso haben Sie das Recht, jederzeit, ohne Angabe von Gründen, der Verarbeitung Ihrer personenbezogenen Daten zu widersprechen. In diesem Fall werden wir die weitere Verarbeitung Ihrer personenbezogenen Daten sofort einstellen und die Daten unwiederbringlich vernichten. Bitte beachten Sie, dass dadurch eine weitere Zusammenarbeit ausgeschlossen wird oder Dienstleistungen unsererseits nicht mehr möglich sind. Wenn Sie Auskunft über Ihre personenbezogenen Daten beziehungsweise deren Korrektur oder Löschung wünschen sowie Widerspruch einlegen möchten oder weitergehende Fragen über die Verwendung Ihrer uns überlassenen personenbezogenen Daten haben, kontaktieren Sie bitte: THE POS KG, Schleifmühlgasse 12-14/L7, office[@]curryinsel.at</p>
<p>Jeder Betroffene hat jederzeit das Recht, sich bei Unzufriedenheit, Unstimmigkeiten oder bei einem Verdacht auf Unregelmäßigkeiten direkt an die Datenschutzbehörde seines Heimatlandes zu richten. Beachten sie, dass sich Datenschutzbestimmungen und Handhabungen zum Datenschutz laufend ändern können. Es ist daher ratsam und erforderlich, sich über Veränderungen der gesetzlichen Bestimmungen und der Praxis von Unternehmen, z. B. Google, laufend zu informieren. Für alle Webseitenbetreiber, die einen Link zu dieser Seite ihrer jeweiligen Homepage einfügt haben, gilt, dass die Textelemente jeder hier enthaltenen Datenschutzerklärung nur beispielhaften Charakter haben und keinen Anspruch auf Vollständigkeit, Richtigkeit und Aktualität erheben sowie keine individuelle Rechtsberatung ersetzen können. Ein Rechtsberatungsverhältnis oder ein sonst irgendwie bindendes Rechtsverhältnis kommt durch die Nutzung dieser Seiten und Inhalte nicht zustande. </p>
  </div> 
  </div>
  </div>
    );
  }
}
export default Privacy; 