import React from 'react';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import PhoneIcon from '@mui/icons-material/Phone';
import RoomIcon from '@mui/icons-material/Room';
import { Link } from 'react-router-dom';
class Weihnachtsmenukarte extends React.Component {
   
  render() {
    var height = window.innerHeight-( window.innerHeight*0.25);
    return (
        <>
        <p>Wir haben hier neue Angebote für Sie.</p>
 
        <img src='/Weihnachtsmenukarte.jpg' style={{width:"100%"}}/>
        </>
        );
  }
}
export default Weihnachtsmenukarte; 