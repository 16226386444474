import React from 'react';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import PhoneIcon from '@mui/icons-material/Phone';
import RoomIcon from '@mui/icons-material/Room';
import { Link } from 'react-router-dom';
class Contact extends React.Component {
   

  render() {
    return (
        <>
       <header class="entry-header">
				<h1 class="entry-title page-title" > Kontakt</h1>	</header>
        <h4 class="   rt-heading" >GET IN TOUCH</h4>
        <p>
        <span style={{    'font-size': 'larger'}}><font  face="Roboto">Bei Fragen, Anregungen und Wünschen freuen wir uns über jede Nachricht!</font></span>
        
        
        </p>
        <p> <Link to="mailto:office@curryinsel.at" style={{ textDecoration: 'underline',color:'#9f2f3c' }}>
<LocalPostOfficeIcon></LocalPostOfficeIcon>
&nbsp;<span style={{    'font-size': 'larger'}}>office@curryinsel.at</span></Link></p>
 <p> <Link to="tel:019208547" style={{ textDecoration: 'underline',color:'#9f2f3c' }}> <PhoneIcon></PhoneIcon> <span style={{    'font-size': 'larger'}}> 019208547</span>
 </Link>
 </p>
        <p class="elementor-heading-title elementor-size-default">
          <Link to="https://maps.app.goo.gl/K69dtbi3mYZk1BMi8" target="_blank" style={{ textDecoration: 'underline',color:'#9f2f3c' }}><RoomIcon></RoomIcon>
          <span style={{    'font-size': 'larger'}}>Schleifmühlgasse 12-14| 1040 Vienna</span></Link> </p>		

        </>
        );
  }
}
export default Contact; 