import axios from 'axios';

//alert(location.host)
//var DOMAIN = "https://order.thepointofsale.at/website/";
//DOMAIN = "http://localhost:9010/"
//DOMAIN = "https://pokepi.co/api/v2/pokeon/5";
export var SHOPREFID = "curryinsel";

axios.defaults.headers = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
  };

const handleResponse = (response) => {
    console.log("Response in handle response");
    if( response.status ===200 || response.status == 201){
        return response.data;
    }
}
const getDomain = ()=>{
    if(window.location.host.startsWith("localhost")){
        return "http://localhost:9010/"
    }
    return window.location.origin +"/website/";
}

export const fetchResponse = async (dataurl,method,headers, params) =>{
    return await axios({ url:getDomain()+dataurl,method,headers,params:params})
        .then(response=> handleResponse(response))
        .catch(error => {
            console.log(error);
            return error;
        })

}

export const fetchResponseWithBody = async (dataurl,method,headers, params,body) =>{
    return await axios({ url:getDomain()+dataurl,method,headers,params:params, data : body,withCredentials: false})
        .then(response=> handleResponse(response))
        .catch(error => {
            console.log(error);
            return error;
        })

}


