import React from 'react';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import PhoneIcon from '@mui/icons-material/Phone';
import RoomIcon from '@mui/icons-material/Room';
import { Link } from 'react-router-dom';
class Kulinarische extends React.Component {
   
  render() {
    var height = window.innerHeight-( window.innerHeight*0.25);
    return (
        <>
        
       <embed src="Speisekarte deutsch final.pdf#toolbar=0" width="100%" height={height} type="application/pdf">
</embed>		

        </>
        );
  }
}
export default Kulinarische; 