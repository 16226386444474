import React, { useEffect, useState } from 'react';  
import 'bootstrap/dist/css/bootstrap.min.css';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { Image } from '@mui/icons-material';
const ColorButton = styled(Button)(({ theme }) => ({
  color: '#d7ae2f',
  backgroundColor: '#112f8a',
  '&:hover': {
    backgroundColor: "#9f2f3c",
  },
}));

class IndexElement extends React.Component
{
  constructor(props) {
    super(props);
    this.state ={htmlFileString : ""};
  }

  
  async fetchHtml () {
    this.setState({htmlFileString: await (await fetch(`Mittagsmenükarte-converted.html`)).text()});
    
    }
    componentDidMount(){
       this.fetchHtml();
  }
  
  
  render()
  {
    var height = window.innerHeight-( window.innerHeight*0.25);
    //var perf =require('./../../Mittagsmenükarte-converted.html');
    return (
      <div class="continer-fluid p-3" >
      
       
       
        {/* <Box sx={{ flexGrow: 1, display: { sm: 'flex', md: 'none' ,lg:'none'} }}>
        <img src='/curryHome.jpeg' style={{width:'100%'}}/>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' ,lg:'none'} }}>
        <img src='/curryHome.jpeg' style={{width:'80%',margin:'auto'}}/>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'none',lg:'flex' } }}>
        <img src='/curryHome.jpeg' style={{width:'50%',margin:'auto'}}/>
        </Box> */}
        
        <h6>Herzlich willkommen!</h6>
      <p>Jeden Samstag und Sonntag (auch an Feiertagen) nehmen wir Sie mit unserem neuen Frühstücks- oder Brunchangebot auf eine Reise durch die weniger
      bekannten Speisen Sri Lankas mit – freuen Sie sich auf eine Zusammenstellung neuer Geschmackserlebnisse!</p>


        <h6>Vorab können Sie unter <a href='/brunch'><b>FRÜHSTÜCK & BRUNCH</b> </a>in unseren Speisekarten schmökern. </h6>
        <div>Unter <b>AKTUELLES</b> finden Sie unsere <a href='/Weihnachtsmenu'>Weihnachts</a>- und <a href='/Mittagsmenu'>Mittagsmenükarte</a>. Wir bieten auch diverse Angebote für Firmenfeiern an. Kontaktieren Sie uns.
        </div>
<p>Tauchen Sie ein in die srilankische Küche.</p>
<h2>Wir ersuchen um Reservierung!</h2>
        <div class="jumbotron" style={{ 
          width: "100%",
          // height: "400px",
          marginBottom:"20px",
          "background-size": "100% 100%",
        //  "background-position": "center",
     // backgroundImage: `url("./background.jpeg")` 
    }}>
      
		            <h2 class="jumbotron-description-container">
                <span class="jumbotron-description-left-dash ml-auto"></span>
                <img src='/Weihnachtsmenukarte.jpg' style={{width:"100%",marginBottom:"20px"}}/>
                <span class="jumbotron-description lead">Spezialitäten aus Sri Lanka</span>
                <span class="jumbotron-description-right-dash mr-auto"></span>
            </h2>
            <h1 class="lead">Curryinsel</h1>
            {/* <p style={{"font-size": "1.25rem"}}>Derzeit nur Barzahlung möglich</p>
            <p style={{"font-size": "1.25rem"}}>Cash only</p> */}
            <Link to={"/reservierung"} style={{ textDecoration: 'none' }}><ColorButton variant="contained" color='secondary'>RESERVIERUNG</ColorButton></Link>         
            </div>

           
            
{/* 
            < iframe src="Mittagsmenükarte.pdf"  width="100%" height={height}>
            <div dangerouslySetInnerHTML={{ __html: this.state.htmlFileString }}></div>
              
              </iframe>  */}
            {/* <object   data="https://docs.google.com/gview?embedded=true&url=https://www.curryinsel.at/Fru%CC%88hstu%CC%88cks-Brunchkarte_Curry%20Insel.pdf#toolbar=0" width="100%" height={height} type="application/pdf">
            </object>		  */}


        
    </div>
    )
  }
}
export default IndexElement;