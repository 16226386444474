import React from 'react';
import './Header.css';
import img from './logo-1.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import ResponsiveAppBar from './components/AppBar';


class Header extends React.Component
{
  render()
  {
    return (
      
      <div class="continer-fluid p-3">
        <ResponsiveAppBar></ResponsiveAppBar>
        
    </div>
    )
  }
}
export default Header;