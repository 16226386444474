import React from 'react';  
import 'bootstrap/dist/css/bootstrap.min.css';
import { Box } from '@mui/material';

class Catering extends React.Component
{
  render()
  {
    return (
      <div class="continer-fluid p-3" /* style={{backgroundImage: `url("/POS_Fulltable5.jpg")`}} */>
        {/* <img src='/POS_Fulltable5.jpg' style={{width:'100%'}}/> */}
        <Box sx={{ flexGrow: 1, display: { sm: 'flex', md: 'none' ,lg:'none'} }}>
        <img src='/curryHome.jpeg' style={{width:'100%'}}/>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' ,lg:'none'} }}>
        <img src='/curryHome.jpeg' style={{width:'80%',margin:'auto'}}/>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'none',lg:'flex' } }}>
        <img src='/curryHome.jpeg' style={{width:'50%',margin:'auto'}}/>
        </Box>
        
        <p><b>lassen sie nichts anbrennen...</b></p>
<p> Wir geben jedem Anlass den exotischen Kick!</p>
<p><b>Anfragen richten sie bitte an:</b> 014069233 <span><b>oder</b></span> office@curryinsel.at</p>


<p> Ab einem Netto-Bestellwert von &euro; 600,- entfällt die Zustellgebühr innerhalb von Wien.<br/>
Für die bestellten Speisen stellen wir auch Warmhaltegeschirr frei zur Verfügung.<br/>
Zustellgebühr : &euro; 40, - pro Fahrt</p>
       
    </div>
    )
  }
}
export default Catering;